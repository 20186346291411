.add-job-form {
    max-width: 1320px;
    /* margin: auto; */
    padding: 20px;

  }
  
  .form-container_jobform {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .form-group_jobform {
    flex: 1 1 45%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  
  .form-group_jobform label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .input-field_jobform {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .full-width_jobform {
    flex: 1 1 100%;
  }
  
  .submit-button_addjob {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button_addjob:hover {
    background-color: #0056b3;
  }
  .job_table_main{
    max-width: 1500px;
    margin: auto;
    padding: 20px;
}
.job_table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .td_job, .th_job {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
 .th_job {
    background-color: #f4f4f4;
  }
  
  .button_job_edit_delete {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
 .button_job_edit_delete:hover {
    opacity: 0.7;
  }
  