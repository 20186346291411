
.main_div_signin {
    width: 100%;
    /* padding-bottom: 25%; */
    margin-top: 130px;
  }
  
  .main_div_form_signin {
    width: 100%;
    height: 650px;
    /* background-image: url("./../../Assets/bg_sigup_form.png"); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateY(-12%);
  }
  
  .form_main_div_sign_in {
    margin-left: 17%;
    width: 66%;
    height: 518px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  }
  
  .padding_div_form_signin {
    padding-left: 7%;
  }
  .heading_in_form_sign_in {
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    padding-top: 5%;
  }
  .input_style_firstname_signin {
    padding: 13px 0px 13px 20px;
    border-radius: 30px;
    border: 1px solid rgba(35, 35, 35, 0.12);
    background: #f7f4f4;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
    margin-bottom: 3%;
  }
  .input_firstname_style_signin {
    outline: none;
    background-color: #f7f4f4;
    border-color: none;
    border-style: none;
    padding-left: 5%;
    width: 85%;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .eye_style_signin {
    margin-left: 2%;
    width: 18px;
    height: 18px;
    margin-top: 1%;
    cursor: pointer;
  }
  
  .orange_button_signin {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #f90;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }
  .blue_button_signin {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #2859c5;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }
  
  .main_div_buttons_signin {
    text-align: center;
  }
  
  .or_para_signin {
    margin-top: 2%;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .para_orange_forgot_your_password {
    color: #f90;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-decoration-line: underline;
    margin-right: 11%;
  }
  .para_orange_forgot_your_password:hover {
    color: #f90;
  }
  
  .accept_term_in_signin {
    color: #f00;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 0px;
    text-decoration-line: underline;
    margin-left: 12%;
    margin-top: 3%;
  }
  
  /* Hide the default checkbox */
  .checkbox_style_sign_in {
    display: block;
    margin-left: 30px;
  }
  
  /* Style the label to look like a checkbox */
  .checkbox_style_sign_in + label {
    position: relative;
    padding-left: 37px; /* Adjust as needed for your design */
    cursor: pointer;
  
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-left: 4.5%;
    margin-bottom: 5%;
  }
  
  /* Style the tick mark */
  .checkbox_style_sign_in:checked + label:after {
    content: "\2713"; /* Unicode checkmark character */
    color: #00b5e3; /* Color for the checkmark when selected */
    position: absolute;
    left: 0;
    font-weight: bold;
    padding-left: 4px;
    padding-bottom: 2px;
  }
  
  /* Style the background when checkbox is selected */
  .checkbox_style_sign_in:checked + label:before {
    background-color: #8fbffa; /* Background color when selected */
    border: 2px solid #2859c5; /* Border color when not selected */
    background-color: #2859c5;
  }
  
  /* Style the border when checkbox is not selected */
  .checkbox_style_sign_in + label:before {
    content: "";
    display: inline-block;
    width: 20px; /* Adjust as needed for your design */
    height: 20px; /* Adjust as needed for your design */
    border: 1px solid #2859c5; /* Border color when not selected */
    background-color: #8fbffa; /* Background color when not selected */
    position: absolute;
    left: 0;
    border-radius: 3px;
  }
  
  /* Hide the original checkbox visually */
  .checkbox_style_sign_in {
    opacity: 0;
    position: absolute;
  }
  .term_policy_para_in_signin {
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 5px;
  }
  @media only screen and (max-width: 1400px) {
    .main_div_signin {
      width: 100%;
      padding-bottom: 20%;
    }
  
    .main_div_form_signin {
      width: 100%;
      height: 650px;
      /* background-image: url("./../../Assets/bg_sigup_form.png"); */
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateY(-12%);
    }
  
    .form_main_div_sign_in {
      margin-left: 17%;
      width: 66%;
      height: 518px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    }
  
    .padding_div_form_signin {
      padding-left: 7%;
    }
    .heading_in_form_sign_in {
      color: #232323;
      font-family: "Poppins", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px;
      padding-top: 5%;
    }
    .input_style_firstname_signin {
      padding: 13px 0px 13px 20px;
      border-radius: 30px;
      border: 1px solid rgba(35, 35, 35, 0.12);
      background: #f7f4f4;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 90%;
      margin-bottom: 3%;
    }
    .input_firstname_style_signin {
      outline: none;
      background-color: #f7f4f4;
      border-color: none;
      border-style: none;
      padding-left: 5%;
      width: 82%;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .eye_style_signin {
      margin-left: 2%;
      width: 18px;
      height: 18px;
      margin-top: 1%;
    }
  
    .orange_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #f90;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
    .blue_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #2859c5;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
  
    .main_div_buttons_signin {
      text-align: center;
    }
  
    .or_para_signin {
      margin-top: 2%;
    }
    .para_orange_forgot_your_password {
      color: #f90;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      text-decoration-line: underline;
      margin-right: 11%;
    }
    .accept_term_in_signin {
      color: #f00;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 0px;
      text-decoration-line: underline;
      margin-left: 13.9%;
      margin-top: 3%;
    }
  
    /* Hide the default checkbox */
    .checkbox_style_sign_in {
      display: block;
      margin-left: 30px;
    }
  
    /* Style the label to look like a checkbox */
    .checkbox_style_sign_in + label {
      position: relative;
      padding-left: 37px; /* Adjust as needed for your design */
      cursor: pointer;
  
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      margin-left: 5.5%;
      margin-bottom: 5%;
    }
  
    /* Style the tick mark */
    .checkbox_style_sign_in:checked + label:after {
      content: "\2713"; /* Unicode checkmark character */
      color: #00b5e3; /* Color for the checkmark when selected */
      position: absolute;
      left: 0;
      font-weight: bold;
      padding-left: 5px;
      padding-bottom: 2px;
    }
  
    /* Style the background when checkbox is selected */
    .checkbox_style_sign_in:checked + label:before {
      background-color: #8fbffa; /* Background color when selected */
      border: 2px solid #2859c5; /* Border color when not selected */
      background-color: #2859c5;
    }
  
    /* Style the border when checkbox is not selected */
    .checkbox_style_sign_in + label:before {
      content: "";
      display: inline-block;
      width: 20px; /* Adjust as needed for your design */
      height: 20px; /* Adjust as needed for your design */
      border: 1px solid #2859c5; /* Border color when not selected */
      background-color: #8fbffa; /* Background color when not selected */
      position: absolute;
      left: 0;
      border-radius: 3px;
    }
  
    /* Hide the original checkbox visually */
    .checkbox_style_sign_in {
      opacity: 0;
      position: absolute;
    }
    .term_policy_para_in_signin {
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-left: 5px;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .main_div_signin {
      width: 100%;
      padding-bottom: 20%;
    }
  
    .main_div_form_signin {
      width: 100%;
      height: 650px;
      /* background-image: url("./../../Assets/bg_sigup_form.png"); */
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateY(-12%);
    }
  
    .form_main_div_sign_in {
      margin-left: 17%;
      width: 66%;
      height: 518px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    }
  
    .padding_div_form_signin {
      padding-left: 7%;
    }
    .heading_in_form_sign_in {
      color: #232323;
      font-family: "Poppins", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px;
      padding-top: 5%;
    }
    .input_style_firstname_signin {
      padding: 13px 0px 13px 20px;
      border-radius: 30px;
      border: 1px solid rgba(35, 35, 35, 0.12);
      background: #f7f4f4;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 90%;
      margin-bottom: 3%;
    }
    .input_firstname_style_signin {
      outline: none;
      background-color: #f7f4f4;
      border-color: none;
      border-style: none;
      padding-left: 5%;
      width: 82%;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .eye_style_signin {
      margin-left: 2%;
      width: 18px;
      height: 18px;
      margin-top: 1%;
    }
  
    .orange_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #f90;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
    .blue_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #2859c5;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
  
    .main_div_buttons_signin {
      text-align: center;
    }
  
    .or_para_signin {
      margin-top: 2%;
    }
    .para_orange_forgot_your_password {
      color: #f90;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      text-decoration-line: underline;
      margin-right: 11%;
    }
    .accept_term_in_signin {
      color: #f00;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 0px;
      text-decoration-line: underline;
      margin-left: 15.7%;
      margin-top: 3%;
    }
  
    /* Hide the default checkbox */
    .checkbox_style_sign_in {
      display: block;
      margin-left: 30px;
    }
    /* Style the label to look like a checkbox */
    .checkbox_style_sign_in + label {
      position: relative;
      padding-left: 37px; /* Adjust as needed for your design */
      cursor: pointer;
  
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      margin-left: 5.9%;
      margin-bottom: 5%;
    }
  
    /* Style the tick mark */
    .checkbox_style_sign_in:checked + label:after {
      content: "\2713"; /* Unicode checkmark character */
      color: #00b5e3; /* Color for the checkmark when selected */
      position: absolute;
      left: 0;
      font-weight: bold;
      padding-left: 6px;
      padding-bottom: 1px;
    }
  
    /* Style the background when checkbox is selected */
    .checkbox_style_sign_in:checked + label:before {
      background-color: #8fbffa; /* Background color when selected */
      border: 2px solid #2859c5; /* Border color when not selected */
      background-color: #2859c5;
    }
  
    /* Style the border when checkbox is not selected */
    .checkbox_style_sign_in + label:before {
      content: "";
      display: inline-block;
      width: 20px; /* Adjust as needed for your design */
      height: 20px; /* Adjust as needed for your design */
      border: 1px solid #2859c5; /* Border color when not selected */
      background-color: #8fbffa; /* Background color when not selected */
      position: absolute;
      left: 0;
      border-radius: 3px;
    }
  
    /* Hide the original checkbox visually */
    .checkbox_style_sign_in {
      opacity: 0;
      position: absolute;
    }
    .term_policy_para_in_signin {
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-left: 5px;
    }
  }
  @media only screen and (max-width: 768px) {
    .main_div_signin {
      width: 100%;
      padding-bottom: 35%;
    }
  
    .main_div_form_signin {
      width: 100%;
      height: 650px;
      background-image: none;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateY(-5%);
    }
  
    .form_main_div_sign_in {
      margin-left: 5%;
      width: 90%;
      height: 518px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    }
  
    .padding_div_form_signin {
      padding-left: 7%;
    }
    .heading_in_form_sign_in {
      color: #232323;
      font-family: "Poppins", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px;
      padding-top: 5%;
    }
    .input_style_firstname_signin {
      padding: 13px 0px 13px 20px;
      border-radius: 30px;
      border: 1px solid rgba(35, 35, 35, 0.12);
      background: #f7f4f4;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 90%;
      margin-bottom: 3%;
    }
    .input_firstname_style_signin {
      outline: none;
      background-color: #f7f4f4;
      border-color: none;
      border-style: none;
      padding-left: 5%;
      width: 78%;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .eye_style_signin {
      margin-left: 2%;
      width: 18px;
      height: 18px;
      margin-top: 1%;
    }
  
    .orange_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #f90;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
    .blue_button_signin {
      width: 180px;
      height: 50px;
      align-items: center;
      border-radius: 30px;
      background: #2859c5;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
  
    .main_div_buttons_signin {
      text-align: center;
    }
  
    .or_para_signin {
      margin-top: 2%;
    }
  }
  @media only screen and (max-width: 515px) {
    .main_div_signin {
      width: 100%;
      padding-bottom: 55%;
    }
  
    .main_div_form_signin {
      width: 100%;
      height: 650px;
      background-image: none;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateY(-5%);
    }
  
    .form_main_div_sign_in {
      margin-left: 5%;
      width: 90%;
      height: 518px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    }
  
    .padding_div_form_signin {
      padding-left: 7%;
    }
    .heading_in_form_sign_in {
      color: #232323;
      font-family: "Poppins", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px;
      padding-top: 5%;
    }
    .input_style_firstname_signin {
      padding: 13px 0px 13px 20px;
      border-radius: 30px;
      border: 1px solid rgba(35, 35, 35, 0.12);
      background: #f7f4f4;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 90%;
      margin-bottom: 3%;
    }
    .input_firstname_style_signin {
      outline: none;
      background-color: #f7f4f4;
      border-color: none;
      border-style: none;
      padding-left: 5%;
      width: 78%;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .eye_style_signin {
      margin-left: 2%;
      width: 18px;
      height: 18px;
      margin-top: 1%;
    }
  
    .orange_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #f90;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
    .blue_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #2859c5;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
  
    .main_div_buttons_signin {
      text-align: center;
    }
  
    .or_para_signin {
      margin-top: 2%;
    }
  
    .accept_term_in_signin {
      color: #f00;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 0px;
      text-decoration-line: underline;
      margin-left: 15.5%;
      margin-top: 3%;
    }
  
    /* Hide the default checkbox */
    .checkbox_style_sign_in {
      display: block;
      margin-left: 30px;
    }
  
    /* Style the label to look like a checkbox */
    .checkbox_style_sign_in + label {
      position: relative;
      padding-left: 37px; /* Adjust as needed for your design */
      cursor: pointer;
  
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 10px;
      font-style: 20px;
      font-weight: 300;
      line-height: 20px;
      margin-left: 6%;
      margin-bottom: 5%;
    }
  
    /* Style the tick mark */
    .checkbox_style_sign_in:checked + label:after {
      content: "\2713"; /* Unicode checkmark character */
      color: #00b5e3; /* Color for the checkmark when selected */
      position: absolute;
      left: 0;
      font-weight: bold;
      padding-left: 6px;
      padding-bottom: 2px;
    }
  
    /* Style the background when checkbox is selected */
    .checkbox_style_sign_in:checked + label:before {
      background-color: #8fbffa; /* Background color when selected */
      border: 2px solid #2859c5; /* Border color when not selected */
      background-color: #2859c5;
    }
  
    /* Style the border when checkbox is not selected */
    .checkbox_style_sign_in + label:before {
      content: "";
      display: inline-block;
      width: 20px; /* Adjust as needed for your design */
      height: 20px; /* Adjust as needed for your design */
      border: 1px solid #2859c5; /* Border color when not selected */
      background-color: #8fbffa; /* Background color when not selected */
      position: absolute;
      left: 0;
      border-radius: 3px;
    }
  
    /* Hide the original checkbox visually */
    .checkbox_style_sign_in {
      opacity: 0;
      position: absolute;
    }
    .term_policy_para_in_signin {
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 10px;
      font-style: 20px;
      font-weight: 600;
      line-height: 20px;
      margin-left: 5px;
    }
  }
  @media only screen and (max-width: 400px) {
    .main_div_signin {
      width: 100%;
      padding-bottom: 50%;
    }
  
    .main_div_form_signin {
      width: 100%;
      height: 650px;
      background-image: none;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateY(-3%);
    }
  
    .form_main_div_sign_in {
      margin-left: 5%;
      width: 90%;
      height: 518px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
    }
  
    .padding_div_form_signin {
      padding-left: 7%;
    }
    .heading_in_form_sign_in {
      color: #232323;
      font-family: "Poppins", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px;
      padding-top: 5%;
    }
    .input_style_firstname_signin {
      padding: 13px 0px 13px 20px;
      border-radius: 30px;
      border: 1px solid rgba(35, 35, 35, 0.12);
      background: #f7f4f4;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 90%;
      margin-bottom: 3%;
    }
    .input_firstname_style_signin {
      outline: none;
      background-color: #f7f4f4;
      border-color: none;
      border-style: none;
      padding-left: 5%;
      width: 72%;
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .eye_style_signin {
      margin-left: 2%;
      width: 18px;
      height: 18px;
      margin-top: 1%;
    }
  
    .orange_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #f90;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
    .blue_button_signin {
      width: 180px;
      height: 55px;
      align-items: center;
      border-radius: 30px;
      background: #2859c5;
      color: #fff;
      text-align: center;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      outline: none;
      border-color: none;
      border-style: none;
    }
  
    .main_div_buttons_signin {
      text-align: center;
    }
  
    .or_para_signin {
      margin-top: 2%;
    }
  
    .accept_term_in_signin {
      color: #f00;
      font-family: "Rubik", sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 0px;
      text-decoration-line: underline;
      margin-left: 15%;
      margin-top: 3%;
    }
  
    /* Hide the default checkbox */
    .checkbox_style_sign_in {
      display: block;
      margin-left: 15px;
    }
  
    /* Style the label to look like a checkbox */
    .checkbox_style_sign_in + label {
      position: relative;
      padding-left: 27px; /* Adjust as needed for your design */
      cursor: pointer;
  
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 10px;
      font-style: 20px;
      font-weight: 300;
      line-height: 25px;
      margin-left: 3%;
      margin-bottom: 5%;
    }
  
    /* Style the tick mark */
    .checkbox_style_sign_in:checked + label:after {
      content: "\2713"; /* Unicode checkmark character */
      color: #00b5e3; /* Color for the checkmark when selected */
      position: absolute;
      left: 0;
      font-weight: bold;
      padding-left: 6px;
      padding-bottom: 3px;
    }
  
    /* Style the background when checkbox is selected */
    .checkbox_style_sign_in:checked + label:before {
      background-color: #8fbffa; /* Background color when selected */
      border: 2px solid #2859c5; /* Border color when not selected */
      background-color: #2859c5;
    }
  
    /* Style the border when checkbox is not selected */
    .checkbox_style_sign_in + label:before {
      content: "";
      display: inline-block;
      width: 20px; /* Adjust as needed for your design */
      height: 20px; /* Adjust as needed for your design */
      border: 1px solid #2859c5; /* Border color when not selected */
      background-color: #8fbffa; /* Background color when not selected */
      position: absolute;
      left: 0;
      border-radius: 3px;
    }
  
    /* Hide the original checkbox visually */
    .checkbox_style_sign_in {
      opacity: 0;
      position: absolute;
    }
    .term_policy_para_in_signin {
      color: #6d6d6d;
      font-family: "Rubik", sans-serif;
      font-size: 7px;
      font-style: 20px;
      font-weight: 600;
      line-height: 25px;
      margin-left: 3px;
    }
  }
  
  
  
  