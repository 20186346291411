/* Book.css */

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  animation: slideInLeft 0.9s forwards; /* Trigger animation when visible */
}

.slide-in-right {
  animation: slideInRight 0.9s forwards; /* Trigger animation when visible */
}


.book_ul {
  list-style-type: none;

  padding: 0 !important;
  margin: 0;
}

.book_ul .book_li {
  background-image: url("../../Assets/Book/list.png");
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 20px 20px;
  padding-left: 30px;
  margin-bottom: 15px;
}
.btn_book {
  background: #4e91ff;
}
.btn_div {
  display: flex;
  justify-content: center;
}
.book_heading {
  font-family: Kepler Std;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 3px;
  text-align: center;
  color: #002356;
  margin-bottom: 4rem;
}
.welcome_book {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 30.08px;
  letter-spacing: 3px;
  text-align: left;
  color: #000000;
}
.content_book {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 30.08px;
  letter-spacing: 1.600000023841858px;
  text-align: left;
  color: #000000;
}
.book_now {
  font-family: Kepler Std;
  font-size: 50px;
  font-style: italic;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: 5px;
  text-align: left;
  color: #000000;
}
.form_back {
  box-shadow: 0px 0px 10px 0px #00000033;
  /* width: 456px; */
  height: 100%;
  left: 1009px;
  padding: 20px 39.86px 19px 40px;
  gap: 0px;
}
.success-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
  text-align: center; /* Center align the message */
}

@media screen and (min-width: 1440px) {
  .form_values {
    margin-bottom: 20px;
  }

}
@media screen and (max-width: 1440px) {
  .book_now {
    font-size: 40px;
  }

}
@media screen and (max-width: 425px) {
  .book_heading {
    line-height: 36px;
  }
  .book_heading {
    font-size: 26px;
  }
  .book_now {
    font-size: 26px;
  }
  .content_book {
    font-size: 14px;
    line-height: 20.08px;
  }
}
