@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.btn.active {
  background-color: grey; /* Red background for the active tab */
  color: white; /* White text color to contrast with the red background */
}
.add_home_reach_btn{
  background: #4E91FF;
  width: 162.44px;
  height: 52.08px;
  top: 481px;
  left: 1312px;
  padding: 10.61px;
  gap: 0px;
  border-radius: 5.47px;
color: #FFFFFF;
  
}
.container {
  width: 77%;
  margin: 0 auto;
}

.tabs-section {
  margin: 35px 0px 83px 0px;
}

.tabs-container {
  display: flex;
  align-items: center;
}

.tabs-container h1 {
  font-size: 34px;
  color: #4b4845;
  line-height: 40px;
}

.tabs {
  margin-left: 232px;
  background-color: #f0eeff;
  padding: 5px 9px 5px 5px;
  border-radius: 9px;
}

.btn {
  font-size: 16px;
  padding: 5px 14px 5px 14px;
  border: none;
  background-color: #f0eeff;
  border-radius: 4.43px;
  color: #4b4845;
  cursor: pointer;
}

.btn-middle {
  margin: 0px 8px;
}

.btn:hover {
  background-color: #ffffff;
  color: #000;
  padding: 5px 14px 5px 14px;
}

/* Home Styling */

.home h3 {
  margin-bottom: 18px;
  color: #4B4B45;
  font-size: 34px;
  line-height: 40.7px;
}

.form {
    margin-bottom: 62px;
    width: 62%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
}

.form input {
    display: block;
    width: 90%;
    border: none;
    background-color: #F6F7F9;
    padding: 18px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    font-weight: 300;
    color: #111111;
}

.form label {
    color: #7A7A7A;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto' sans-serif;
    line-height: 27.2px;
    margin-bottom: 4px;
} 

.form button {
    padding: 20px 60px;
    font-size: 14px;
    line-height: 13px;
    font-weight: 400;
    letter-spacing: 2.19px;
    background-color: #4E91FF;
    color: white;
    border: none;
    cursor: pointer;
    justify-self: end;
    align-self: end;
    grid-column: 2;
    grid-row: 2;
    margin-right: 10%;
    border-radius: 6px;
}



@media screen and (max-width: 1337px) {
  
}

.table-headings {
    background-color: #023496;
}

.spacer-row td {
    padding: 0;
    height: 20px; 
    background-color: transparent; 
    border: none; 
  }

.home th {
    text-align: left;
    color: #FFFFFF;
    font-size: 16px;
    font-family: "Roboto", serif;
    font-weight: 400;
    padding: 18px;
}

.table-data {
    background-color: #f4f4f4;
}

.table-data td {
    padding: 25px 19px;
    font-size: 16px;
    font-weight: 300;
    font-family: "Roboto";
    color: #111111;
}

.table-data td:nth-child(1) {
    letter-spacing: 2.64px;
}

.table-icons img {
    cursor: pointer;
}

.edit-icon {
    padding-right: 17px;
}

.divider {
    background-color: #002356;
    height: 6px;
}

.gallery {
    margin-top: 43px;
}