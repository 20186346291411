@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
    background: #ffffff;
  }
}
.img_padding{
  padding: 12px 10px !important;
}
.navbar-container {
  max-width: 1950px;
  margin: 0 auto;
  width: 100%;
}
.phone_css_nav{
  color: #000;
  text-decoration: none;
}
.nav-item.active .nav-link {
  /* text-decoration: underline;  */
  color: #023496; /* Optional: change color of active link */
}
.link_css.active .nav-link {
  /* text-decoration: underline;  */
  /* Adds an underline */
  color: #023496; /* Optional: change the color when active */
}

.nav-item {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1599999964237213px;
  text-align: center;
  color: #111111;
}
.link_css {
  text-decoration: none;
}
.nabvar_main {
  display: flex;
  justify-content: space-between;
}
.col-xl-9,
.col-lg-8,
.col-md-7,
.col-sm-2 {
  padding: 15px;
}
.top_head {
  display: flex;
  justify-content: space-between;
}
.nav-link {
  text-decoration: none;
  padding-bottom: 5px;
}

.nav-link.active {
  border-bottom: 2px solid #000; /* Change the color and thickness as needed */
}

.navbar-nav {
  display: flex;
  gap: 20px;
}
.btn_enquiry {
  width: 100%;
  height: 100%;
  top: 49px;
  left: 1670px;
  padding: 1rem;
  gap: 0px;
  color: #ffffff;
  background-color: #023496;
  border: none;
}

.contact-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  margin: 10px; /* Add margin for spacing */
}

.icon {
  margin-right: 8px; /* Gap between icons and text */
  color: #333; /* Default icon color */
}

.location {
  margin-right: 16px; /* Gap between location and email */
}

.social-iconss {
  display: flex;
  align-items: center; /* Center align items vertically */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.contact-info {
  display: flex;
  align-items: center; /* Align icon and text vertically */
  margin-right: 20px; /* Gap between the phone and social icons */
}

.icon {
  margin-right: 8px; /* Space between icon and text */
}

.icon-group {
  display: flex; /* Align social icons in a row */
}

.social-icona {
  margin-right: 10px; /* Space between social icons */
  color: #023496; /* Default icon color */
}
/* Container for the nav item and dropdown */
.nav-item {
  position: relative; /* Make sure dropdown is positioned relative to the link */
}

/* Hide the dropdowns by default */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%; /* Position the dropdown below the link */
  left: 0;
  background-color: white; /* Dropdown background */
  border: 1px solid #ddd; /* Border for the dropdown */
  min-width: 200px; /* Minimum width of the dropdown */
  z-index: 1000; /* Make sure the dropdown is above other content */
}

/* Show the dropdown when hovering on the nav item */
.nav-item:hover .dropdown-menu {
  display: block;
}

/* Style for the dropdown content */
.dropdown-content {
  padding: 10px 0;
}

.dropdown-item {
  padding: 8px 16px;
  display: block;
  color: #000;
  text-decoration: none;
}

/* Hover effect on each dropdown item */
.dropdown-item:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width: 768px) {
  .navbar-nav {
    width: 100%;
    position: absolute;
    background: white;
  }
  .navbar_main_sec {
    width: 100%;
    margin-bottom: 2rem;
  }
  .nabvar_main {
    width: 100%;
  }
  .line_straight {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .nabvar_main {
    display: flex;
    justify-content: right !important;
  }
  .yachts_logo {
    width: 180px;
  }
  .contact-info {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: flex-start; /* Align items to the left */
  }
  .top_head {
    display: none;
  }
  .icon {
    margin-right: 0; /* Remove right margin for vertical stacking */
    margin-bottom: 5px; /* Add space below icons */
  }

  .location {
    margin-right: 0; /* Remove right margin for vertical stacking */
    margin-bottom: 5px; /* Add space below location */
  }
  .social-iconss {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: flex-start; /* Align items to the left */
  }

  .contact-info {
    margin-right: 0; /* Remove right margin for vertical stacking */
    margin-bottom: 10px; /* Space below contact info */
  }

  .social-icona {
    margin-right: 0; /* Remove right margin for vertical stacking */
    margin-bottom: 5px; /* Space below social icons */
  }
  .btn_enquiry {
    width: 160px;
    height: 55px;

    padding: 0px 0px 0px 0px;
    gap: 0px;
    color: #ffffff;
    background-color: #023496;
  }
}

@media (min-width: 1440px) {
  .col-xl-9 {
    max-width: 75%; /* Adjust the width as needed for 1440px and larger */
  }

  .navbar {
    padding: 20px; /* Add extra padding or customize further */
  }

  .d-flex {
    justify-content: space-between; /* Ensure content remains spaced out */
  }
}
