@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.about-us-button:hover {
  background-color: #ffba00;
}
/* .content-border:hover .about-us-button,
.content-border:hover {
  border-color: #ffba00; 
} */
.about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reach_about_content {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.09px;
  letter-spacing: 1.8897300958633423px;
  text-align: center;
  color: #ffffff;
}
.social-icon {
  color: #ffffff;
  width: 15px;
}

.about-us-social p {
  margin-left: 14px;
}

.about-us-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.content-border {
  color: #002356;
  border: solid;
  border-color: #00fff2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
}

.content-border p {
  color: white;
  text-shadow: 1px 1px #010013;
  width: 80%;
  text-align: center;
}

.about-us-button {
  font-family: roboto;
  background-color: #00fff2;
  border: 0;
  padding: 5px;
  padding-left: 20px;
  padding-right: 5px;
  transition: background-color 0.3s;
  cursor: pointer;
  position: relative;
}

.about-us-button .arrow {
  margin-left: 5px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s; /* Smooth transition for visibility and position */
}

.about-us-button:hover {
  background-color: #ffba00; /* Change background on hover */
}

.about-us-button:hover .arrow {
  opacity: 1; /* Show the arrow on hover */
  transform: translateX(
    5px
  ); /* Optional: Move the arrow slightly to the right */
}

.about-us-pic-1 {
  background: linear-gradient(180deg, rgba(2, 21, 38, 0) 50%, #021526 100%),
    url("../../Assets/About/about-us-bg-pic1.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 380px;
  height: 500px;
  gap: 0px;
  padding: 20px;
}

.about-us-pic-2 {
  width: 380px;
  height: 500px;
  gap: 0px;
}

.about-us-pic-2-top {
  background: linear-gradient(180deg, rgba(2, 21, 38, 0) 50%, #021526 100%),
    url("../../Assets/About/about-us-bg-pic2.png");
  height: 50%;
  gap: 0px;
  padding: 5%;
}

.about-us-pic-2-bottom {
  background: linear-gradient(180deg, #4e91ff 0%, #002356 100%);
  gap: 0px;
  height: 50%;
}

.about-us-pic-3 {
  background: linear-gradient(180deg, rgba(2, 21, 38, 0) 50%, #021526 100%),
    url("../../Assets/About/about-us-bg-pic3.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 380px;
  height: 500px;
  gap: 0px;
  padding: 2%;
}

.about-us-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 5%;
}

.about-us-p {
  width: 100%;
  text-align: center;
  color: #111111;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.1599999964237213px;
  text-align: center;
}

.about-us-h3 {
  font-family: Kepler Std;
  font-size: 48px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 3px;
  text-align: center;

  color: #023496;
}

.about-us-pic-2-bottom {
  font-family: Kepler Std;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media (max-width: 426px) {
  .about-us-pic-1 {
    background: linear-gradient(180deg, rgba(2, 21, 38, 0) 50%, #021526 100%),
      url("../../Assets/About/about-us-bg-pic1.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 330px;
    height: 500px;
    gap: 0px;
    padding: 20px;
  }

  .about-us-pic-2 {
    width: 330px;
    height: 500px;
    gap: 0px;
  }
  .about-us-content {
    width: 100%;
    padding: 5%;
  }
  .about-us-h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .about-us-p {
    font-size: 16px;

    line-height: 30px;

    text-align: start !important;
  }
  .about-us-pic-3 {
    background: linear-gradient(180deg, rgba(2, 21, 38, 0) 50%, #021526 100%),
      url("../../Assets/About/about-us-bg-pic3.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 330px;
    height: 500px;
    gap: 0px;
    padding: 20px;
  }
  .about-us-p {
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 1440px) {
  .about-us-p {
    width: 70%;
  }
}
