.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.gallery-item {
  flex: 0 1 calc(25% - 10px); 
  margin: 5px; 
  box-sizing: border-box; 
}

.gallery-item img {
  width: 100%; 
  height: 150px; 
  object-fit: cover; 
  border-radius: 2px; 
}

.picture_heading {
  display: flex;
  justify-content: center;
  font-family: Kepler Std;
  font-size: 50px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 3px;
  text-align: center;
  color: #002356;
  margin: 3rem 1rem 1rem 1rem;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .gallery-item {
    flex: 0 1 calc(50% - 10px); /* 2 items per row on medium screens */
  }
}

@media (max-width: 768px) {
  .gallery-item {
    flex: 0 1 calc(33.33% - 10px); /* 3 items per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .gallery-item {
    flex: 0 1 calc(100% - 10px); /* 1 item per row on extra small screens */
  }
  .picture_heading {
    font-size: 26px;
  }
}
