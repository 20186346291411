/* .video-section .item {
  opacity: 0.4;
  transition: 0.4s ease all;
  margin: 0 20px;
  transform: scale(0.8);
} */
.owl-nav{
    display: flex;
    justify-content: space-between;
}
@media (max-width: 1000px) {
  .video-section .item {
    margin: 0;
    transform: scale(0.9);
  }
}

.video-section .active .item {
  opacity: 1;
  transform: scale(1);
}

body {
  margin: 80px 0 0 0;
}

.video-section .owl-item {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

.video-section video {
  max-width: 100%;
  height: auto;
}
.video-section .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .video-section .owl-nav button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
 
  
  .video-section .owl-nav .owl-prev {
    left: 10px;
  }
  
  .video-section .owl-nav .owl-next {
    right: 10px;
  }
  