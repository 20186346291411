.contact_back_crew {
    /* background: url("../../Assets/Crew/looking_cre_back.png"); */
    background: url("../../Assets/Crew/Page\ Header\ BG\ 4-min.png");
    width: 100%;
    height: 258px;
    background-position: top;
  }

.positions, .skills {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.label_looking_crew {
    display: inline-block;
    margin-right: 15px;
}
.submit_looking_crew{
    width: Fixed (152px)px;
height: Hug (52px)px;
top: 2035.38px;
left: 561.86px;
padding: 16px 32px 16px 32px;
gap: 0px;
border-radius: 5px;
background: #4E91FF;
border: none;
color: #fff;
margin: 3rem 0rem;
}
.checkbox_privacy{
    padding: 0rem 1rem;

}
.checkbox_css{
    margin-right: 0.5rem;
}
.label-container {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping for responsiveness */
    justify-content: space-between; /* Ensures even spacing */
  }
  
  .label_looking_crew {
    flex: 1 1 18%; /* Makes each label take up around 18% of the width, adjusting to the container */

    text-align: start;
color: #7A7A7A;

  
  }
  .looking_crew_main_heading{
color: #023496;
font-family: Kepler Std;
font-size: 48px;
font-weight: 700;
line-height: 48px;
text-align: center;

  }
  .positionh2{
    color: #0A1B40;
  }
  .looking_crew_main_content{

    font-weight: 400;
 
    text-align: center;
    color:#7A7A7A;
  }
  
  @media (max-width: 1200px) {
    .label_looking_crew {
      flex: 1 1 20%; /* On larger screens like desktop, adjust the label width */
    }
  }
  
  @media (max-width: 768px) {
    .label_looking_crew {
      flex: 1 1 45%; /* For tablet-sized screens, labels will take up 45% of the width */
    }
  }
  
  @media (max-width: 480px) {
    .label_looking_crew {
      flex: 1 1 100%; /* On mobile, each label will take up the full width */
    }
  }
  




  .skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .label_skill_crew {
    flex: 0 1 48%; /* Each label takes 48% width, so two per row */
    color: #7A7A7A;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    .label_skill_crew {
      flex: 0 1 40%; /* Stack labels vertically on smaller screens */
    }
  }
  